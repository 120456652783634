@mixin p-display($dp) {
    @each $d in $display {
        .p-d-#{$dp}-#{$d} {
            display: $d !important;
        }
    }
}
@mixin p-width-auto($au) {
    .w-#{$au}-auto {
        width: auto !important;
    }
}
@mixin p-margin-0($au) {
    .w-#{$au}-auto {
        width: auto !important;
    }
}
@mixin p-width($wd) {
    @each $w in $width {
        .w-#{$wd}-#{$w} {
            width: $w * 1% !important;
        }
        .h-#{$wd}-#{$w} {
            height: $w * 1% !important;
        }
        .vw-#{$wd}-#{$w} {
            width: $w * 1vw !important;
        }
        .vh-#{$wd}-#{$w} {
            height: $w * 1vh !important;
        }
        .wr-#{$wd}-#{$w} {
            width: $w * 1rem !important;
        }
        .hr-#{$wd}-#{$w} {
            height: $w * 1rem !important;
        }
        .we-#{$wd}-#{$w} {
            width: $w * 1em !important;
        }
        .he-#{$wd}-#{$w} {
            height: $w * 1em !important;
        }

        .mw-#{$wd}-#{$w} {
            max-width: $w * 1% !important;
        }
        .mh-#{$wd}-#{$w} {
            max-height: $w * 1% !important;
        }
        .mvw-#{$wd}-#{$w} {
            max-width: $w * 1vw !important;
        }
        .mvh-#{$wd}-#{$w} {
            max-height: $w * 1vh !important;
        }
        .mwr-#{$wd}-#{$w} {
            max-width: $w * 1rem !important;
        }
        .mhr-#{$wd}-#{$w} {
            max-height: $w * 1rem !important;
        }
        .mwe-#{$wd}-#{$w} {
            max-width: $w * 1em !important;
        }
        .mhe-#{$wd}-#{$w} {
            max-height: $w * 1em !important;
        }
    }
}

@include p-display('');
@include p-width('');

@media screen and (min-width: $xs) {

    @include p-display('xs');
    @include p-width('xs');
    @include p-width-auto('xs');
}
@media screen and (min-width: $sm) {
    @include p-display('sm');
    @include p-width('sm');
    @include p-width-auto('sm');


}
@media screen and (min-width: $md) {
    @include p-display('md');
    @include p-width('md');
    @include p-width-auto('md');

}

@media screen and (min-width: $lg) {
    @include p-display('lg');
    @include p-width('lg');
    @include p-width-auto('lg');

}
@media screen and (min-width: $xl) {
    @include p-display('xl');
    @include p-width('xl');
    @include p-width-auto('xl');

}
@media print {
    @include p-display('print');
    @include p-width('print');
    @include p-width-auto('print');
}
