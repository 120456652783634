@media screen and (min-width: $xs) {
}
@media screen and (min-width: $sm) {
}
// tablet
@media screen and (min-width: $md) {
}
// laptop
@media screen and (min-width: $lg) {
}
@media screen and (min-width: $xl) {
}

// responsive table
@media screen and (max-width: $sm) {
    *:not(h1, h2, h3, h4, h5, h6, svg, i) {
        font-size: 0.9rem !important;
    }
    .chartHome {
        display: none !important;
    }
    .m-t-xs-4 {
        // margin-top: 2rem !important;
    }
    .select-doi-tuong {
        .ant-radio-wrapper {
            margin-right: 3em;
        }
        .ant-form-item {
            margin-bottom: 0 !important;
        }
    }
    .custom-file {
        // margin-bottom: 27px;
    }
    .input-group{
        .form-group{
            >label{
                font-weight:600;
            }
        }
        .ant-form-item {
            margin-bottom: 0.25rem !important;
        }
        .label-hide{
            display:none!important;
        }
        .button-a{
            margin-bottom: 0.25rem !important;
        }
    }
    .font-size-12{
        // font-size:11px!important;
    }
    a{
        // height: 100%!important;
        // word-break: break-word !important;
    }
}
