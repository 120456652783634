.Home{
    background-color: #F0F2F5 !important;
    .card_background {
        background-color: #F0F2F5 !important;
        border: none!important;
      }
      .card-dashboard .ant-statistic-title{
        color: #fff !important;
      }
      @mixin box-color($colorL, $colorR){
        background: linear-gradient(to right, $colorL, $colorR);
        flex-direction: column;
        height: 100%;
        border-radius: 5px;
    }
    .Dashboard{
        // flex-direction: column;
        height: 815px!important;
        &__box--orange{
            @include box-color(#FE9365, #FEB697);
        }
        &__box--green{
            @include box-color(#0AC282, #0DF2A3);
        }
        &__box--red{
            @include box-color(#FE5E71, #FE8F9C);
        }
        &__box--blue{
            @include box-color(#2E64FE, #00BFFF);
        }
        &__box--time{
            border-top: 1px solid rgb(236, 236, 236);
        }
        svg{
            // vertical-align: middle!important;
        }
    }
    .wrap-banner-QC{
        // width:60rem;
    }
    .chartHome{
        // height: 50vh;
    }
}