@import "./_overrides";
@import "./_display_visible";
@import "./_custom_responsive";
.ant-layout-sider-collapsed {
    .logo {
        margin: 0.7rem 0 0 0.3rem;
        background-size: 70px;
    }
}
.ant-layout-sider-light{
    .ant-layout-sider-zero-width-trigger{
        top: 0.6rem;
        right: -3.4rem;
        border-radius: 0.2rem;
        width: 2.6rem;
        height: 2.6rem;
    }
}
.border-radius-1{
    border-radius: 0.25rem;
}
.border-radius-2{
    border-radius: 0.5rem;
}
.ant-card-head-title{
    // overflow: auto;
    white-space: revert!important;
    overflow: inherit;
    label{
        word-break: break-word !important;
    }
}

.font-size-12{
    font-size:12px;
}

.ant-calendar-picker{
    width: 100%;
}
.center-x{
    position: absolute;
    left: 50%;
    transform: translateX(-50%);
}
.position-center {
    top: 50%;
    right: 50%;
    -webkit-transform: translate(50%, -50%);
    transform: translate(50%, -50%);
    position: absolute;
}