.bg-c-white {
    background: linear-gradient(to right, #f5f6fa, #dcdde1);
}
.bg-c-left {
    background: linear-gradient(to right, #e67e22, #ffb27f);
}
.bg-c-center {
    background: linear-gradient(to right, #20c997, #a3e0e0);
}
.bg-c-right {
    background: linear-gradient(to right, #e74d95, #fa9bea);
}
.text-c-left {
    color: #ff7979;
}
.text-c-center {
    color: #20c997;
}
.text-c-right {
    color: #e74d95;
}
.butt {
    background-color: #ffb27f;
    height:15px;
    width:50px;
}
.butt1 {
    background-color: #fde880;
    height:15px;
    width:50px;
}
.description{
    font-size: 15px;
}
.number{
    font-size: 30px;
}
.card-feeds{
    margin-top: 10px;
    font-size: 18px;
    font-weight: bold;
    text-align: center;
}
.card-p{
    margin-left: 30px;
    margin-bottom: 40px;
}
.fa-3x{
    font-size: medium;
}
.fa-stack-1x {
    color: white;
}
.text-right{
    padding-right: 15px;
    padding-left: 0px;
}
.number-line-2{
    font-size: 30px;
    color: ivory;
}
.text-muted-2{
    color: ivory;
}
.demo-infinite-container {
    border: 1px solid #e8e8e8;
    border-radius: 4px;
    overflow: auto;
    padding: 8px 24px;
    height: 100%;
    background-color: white;
}
.demo-loading-container {
    position: absolute;
    bottom: 40px;
    width: 100%;
    text-align: center;
}
[data-theme="dark"] .demo-infinite-container {
    border: 1px solid #303030;
}
.ant-list-item{
    padding: 6px !important;
}
.text-right-icon{
    size: 18px;
    text-align: right !important;
    color: ivory;
}
#columnChart {
    width: 100%;
    height: 350px;
}
.chart{
    margin-top: 20px !important;
    width: 100%;
    height: 400px
}
#donutChart {
    width: 100%;
    height: 350px;
}
.title-chart{
    font-size: 20px;
    font-weight: bold;
    color: black;
}