.logo {
  height: 32px;
  margin: 16px 16px 16px 50px;
  background-size: 130px;
  border-radius: 3px;
  background-image: url("/images/logo.png");
  background-color: rgba(255, 255, 255, 0.3);
  background-repeat: no-repeat;
}


.btn-as-block {
  display: block;
}

.content {
  /*padding: 24px;*/
  background-color: #ffffff;
  /*min-height: 360px;*/
}

.ant-input:disabled,
.ant-select-disabled,
.ant-input-number-disabled {
  background-color: #eeeeee !important;
  color: #222831 !important;
}

.ant-radio-disabled + span,.ant-radio-disabled .ant-radio-inner::after  {
  color: #222831 !important;
}

.newca-label {
  color: #212529;
  /*font-weight: 700;*/
}

.card-title1 {
  font-family: Arial, Helvetica, sans-serif;
  font-weight: 700;
  color: #212529;
  margin-bottom: 20px;
  font-size: 25px;
  text-transform: capitalize;
}

.ant-card-head-title {
  font-weight: 600;
  font-size: 25px;
  /*text-transform: capitalize;*/
  font-family: Arial, Helvetica, sans-serif;
  text-transform: uppercase !important;
}

.ant-menu-item {
  height: 50px;
  color: #2e2e2e;
  line-height: 1.5;
  font-weight: 600;
}

.ant-menu-submenu {
  color: #2e2e2e;
  line-height: 1.5;
  font-weight: 600;
}

.card-content {
  font-family: Arial, Helvetica, sans-serif;
  font-size: 20px;
  color: #212529;
}

.custom-spin {
  background: #eeeeee;
  border-radius: 4px;
  height: 100%;
  align-items: center;
  justify-content: center;
  display: flex;
}

.loading-wrapper {
  min-height: 100vh;
  position: fixed;
  z-index: 10000;
  min-width: 100%;
}

.loadding-content-wrapper {
  height: 100%;
  z-index: 100;
  width: 100%;
  position: absolute;
}

.content-card-wrapper {
  position: relative;
}

.nopadding {
  padding: 0;
}

.nopadding-left {
  padding-left: 0;
}

.nopadding-right {
  padding-right: 0;
}

.anticon {
  vertical-align: 0 !important;
}

.cursor-pointer {
  cursor: pointer;
}

.ant-modal-close-x {
  visibility: hidden;
}

.ant-table-header-column {
  font-weight: 700;
}

.cssload-thecube {
  width: 120px;
  height: 120px;
  margin: 0 auto;
  margin-top: 49px;
  position: relative;
  transform: rotateZ(45deg);
  -o-transform: rotateZ(45deg);
  -ms-transform: rotateZ(45deg);
  -webkit-transform: rotateZ(45deg);
  -moz-transform: rotateZ(45deg);
}

.cssload-thecube .cssload-cube {
  position: relative;
  transform: rotateZ(45deg);
  -o-transform: rotateZ(45deg);
  -ms-transform: rotateZ(45deg);
  -webkit-transform: rotateZ(45deg);
  -moz-transform: rotateZ(45deg);
}

.cssload-thecube .cssload-cube {
  float: left;
  width: 50%;
  height: 50%;
  position: relative;
  transform: scale(1.1);
  -o-transform: scale(1.1);
  -ms-transform: scale(1.1);
  -webkit-transform: scale(1.1);
  -moz-transform: scale(1.1);
}

.cssload-thecube .cssload-cube:before {
  content: "";
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: white;
  animation: cssload-fold-thecube 2.76s infinite linear both;
  -o-animation: cssload-fold-thecube 2.76s infinite linear both;
  -ms-animation: cssload-fold-thecube 2.76s infinite linear both;
  -webkit-animation: cssload-fold-thecube 2.76s infinite linear both;
  -moz-animation: cssload-fold-thecube 2.76s infinite linear both;
  transform-origin: 100% 100%;
  -o-transform-origin: 100% 100%;
  -ms-transform-origin: 100% 100%;
  -webkit-transform-origin: 100% 100%;
  -moz-transform-origin: 100% 100%;
}

.cssload-thecube .cssload-c2 {
  transform: scale(1.1) rotateZ(90deg);
  -o-transform: scale(1.1) rotateZ(90deg);
  -ms-transform: scale(1.1) rotateZ(90deg);
  -webkit-transform: scale(1.1) rotateZ(90deg);
  -moz-transform: scale(1.1) rotateZ(90deg);
}

.cssload-thecube .cssload-c3 {
  transform: scale(1.1) rotateZ(180deg);
  -o-transform: scale(1.1) rotateZ(180deg);
  -ms-transform: scale(1.1) rotateZ(180deg);
  -webkit-transform: scale(1.1) rotateZ(180deg);
  -moz-transform: scale(1.1) rotateZ(180deg);
}

.cssload-thecube .cssload-c4 {
  transform: scale(1.1) rotateZ(270deg);
  -o-transform: scale(1.1) rotateZ(270deg);
  -ms-transform: scale(1.1) rotateZ(270deg);
  -webkit-transform: scale(1.1) rotateZ(270deg);
  -moz-transform: scale(1.1) rotateZ(270deg);
}

.cssload-thecube .cssload-c2:before {
  animation-delay: 0.35s;
  -o-animation-delay: 0.35s;
  -ms-animation-delay: 0.35s;
  -webkit-animation-delay: 0.35s;
  -moz-animation-delay: 0.35s;
}

.cssload-thecube .cssload-c3:before {
  animation-delay: 0.69s;
  -o-animation-delay: 0.69s;
  -ms-animation-delay: 0.69s;
  -webkit-animation-delay: 0.69s;
  -moz-animation-delay: 0.69s;
}

.cssload-thecube .cssload-c4:before {
  animation-delay: 1.04s;
  -o-animation-delay: 1.04s;
  -ms-animation-delay: 1.04s;
  -webkit-animation-delay: 1.04s;
  -moz-animation-delay: 1.04s;
}

@keyframes cssload-fold-thecube {
  0%,
  10% {
    transform: perspective(136px) rotateX(-180deg);
    opacity: 0;
  }

  25%,
  75% {
    transform: perspective(136px) rotateX(0deg);
    opacity: 1;
  }

  90%,
  100% {
    transform: perspective(136px) rotateY(180deg);
    opacity: 0;
  }
}

@-o-keyframes cssload-fold-thecube {
  0%,
  10% {
    -o-transform: perspective(136px) rotateX(-180deg);
    opacity: 0;
  }

  25%,
  75% {
    -o-transform: perspective(136px) rotateX(0deg);
    opacity: 1;
  }

  90%,
  100% {
    -o-transform: perspective(136px) rotateY(180deg);
    opacity: 0;
  }
}

@-ms-keyframes cssload-fold-thecube {
  0%,
  10% {
    -ms-transform: perspective(136px) rotateX(-180deg);
    opacity: 0;
  }

  25%,
  75% {
    -ms-transform: perspective(136px) rotateX(0deg);
    opacity: 1;
  }

  90%,
  100% {
    -ms-transform: perspective(136px) rotateY(180deg);
    opacity: 0;
  }
}

@-webkit-keyframes cssload-fold-thecube {
  0%,
  10% {
    -webkit-transform: perspective(136px) rotateX(-180deg);
    opacity: 0;
  }

  25%,
  75% {
    -webkit-transform: perspective(136px) rotateX(0deg);
    opacity: 1;
  }

  90%,
  100% {
    -webkit-transform: perspective(136px) rotateY(180deg);
    opacity: 0;
  }
}

@-moz-keyframes cssload-fold-thecube {
  0%,
  10% {
    -moz-transform: perspective(136px) rotateX(-180deg);
    opacity: 0;
  }

  25%,
  75% {
    -moz-transform: perspective(136px) rotateX(0deg);
    opacity: 1;
  }
  90%,
  100% {
    -moz-transform: perspective(136px) rotateY(180deg);
    opacity: 0;
  }
}

.sider-label{
  font-size: small;
}
.ant-form .form-group{
  margin-bottom: 0px;
}
.w-5{
  width: 5%;
}
.ant-layout-sider-trigger {
  z-index: 0 !important;
}
.ant-table-placeholder {
  z-index: 0 !important;
}
.ant-tag {
  cursor: pointer !important;
}
.ant-card-body .justify-content-center {
  padding-top: 0rem!important;
}
.input-group .col-md-3 .ant-radio-wrapper{
  margin-right: 3em;
}
.select-receive .ant-form-item{
  margin-bottom: 0;
}
/* .input-group .mt-2{
  padding: 0em 1em 2em 1em;
} */
.ant-pagination-item a {
  padding: 4px 6px !important;
}
.label-thong-tin{
  padding-bottom: 1em;
  padding-top: 1em;
  font-weight: bold;
}

.select-type-legal .ant-radio-wrapper{
  margin-right: 2em;
}
.noi-cap{
  padding-left: 0;
}
.radio-to-chuc .ant-radio-wrapper {
  margin-left: 5em;
}
.radio_register_support .ant-radio-wrapper{
  margin-right: 7em;
}
.ant-card-small > .ant-card-head {
    background-color: #d2dae2 !important;
}
.cancel{
  display: inline-flex;
}
.hidden{
    visibility: hidden;
}

.table-gen{
  width: 100%;
}
.float-right{
  float: right;
}

.fieldset1 {
  align-self: center;
  width: 80%;
  border:2px solid black;
  border-radius:8px;
  margin: auto;
  padding: 1em;
  font-family: Arial, Helvetica, sans-serif;
  font-size: 14px;
  color: #212529;
}

.fname{
  margin-left: 2em;
  width: 7em;
  padding-top: 0.7em;
}
.row-result{
  display: inline-flex;
  padding: 0 1em 0 1em;
  height: 3em;
  width: 100%;
}
.row-result input{
  border: hidden;
}
.row-result textarea{
  border: hidden;
  resize: none;
}
.button-result{
  justify-content: center!important;
  padding: 2em 0 2em 0;
}
.right{
  margin-left: 11em;
}
.height-5em{
  height: 5em;
}
.modal-pin{
  width: 50%;
  margin: auto;
}
.input-tree{
  padding-bottom: 1.2em;
}

.bg-light {
    background-color: #FFFFFF!important;
}
/*.custom-file{*/
/*  */
/*  word-wrap: break-word!important;*/
/*}*/
.custom-file{
  margin-top: 0.17em;
  text-overflow: clip ;
  white-space: nowrap!important;
  /*overflow: hidden;*/
  margin-bottom: 27px;
}
.custom-file-nonOverFlow{
  text-overflow: clip ;
  position: inherit;
}
.custom-file-noWrap{
  white-space: nowrap;
  overflow: hidden;
}
.check-box-group .ant-checkbox-wrapper{
  margin: 1em 0 0 1em;
}
.check-box-group .ant-checkbox-wrapper  + .ant-checkbox-wrapper{
  margin-left: 2em;
}


/* .menu-side-left{
    flex: 0 0 200px !important;
    max-width: 230px !important;
    min-width: 230px !important;
    width: 200px !important;
} */

#RequestCTSPersonalSearchForm_created_at_search, #WrappedRequestCTSGroupSearchForm_created_at_search, #GenCertSearchForm_created_at_search,
#RequestTokenTrainingSearchForm_training_date_search, #RequestTokenTrainingSearchForm_created_at_search{
  width: 100%;
}
.margintop{
  margin-top: -0.2em;
}

.drop-contract:hover {
    background-color:#34495e;
}
.fname2{
  padding-top: 0.7em;
  text-align: left;
}
.nopadding-top{
  margin-top: 0;
  width: 60%;
  text-align: left;
}
.row-result2{
  display: inline-flex;
  padding: 0 1em 0 1em;
  height: 3em;
  width: 100%;
}
.row-result2 input{
  border: hidden;
}
.row-result2 textarea{
  border: hidden;
  resize: none;
}
.button-result2{
  justify-content: center!important;
  padding: 1em 0 1em 0;
}

.fieldset2 {
  align-self: center;
  border:2px solid black;
  border-radius:8px;
  margin: auto;
  padding: 1em;
  font-family: Arial, Helvetica, sans-serif;
  font-size: 14px;
  color: #212529;
}
.notify .ant-badge-count{
  margin: 0 0 1em 1em !important;
}
.notify .ant-scroll-number-only-unit{
  color: #fff !important;
}
.ant-table-thead > tr > th{
  text-align: center !important;
}
.ant-menu-sub.ant-menu-inline li{
  font-weight: normal !important;
}
.ant-btn{
  padding: 0 5px !important;
}

.login100-form-title{
  margin-left: auto !important;
  margin-right: auto !important;
}

.box-shadow{
  box-shadow:
          0 4px 6px 0 rgba(85, 85, 85, 0.09019607843137255),
          0 1px 20px 0 rgba(0, 0, 0, 0.08),
          0px 1px 11px 0px rgba(0, 0, 0, 0.06);
}
.ant-menu-inline{
  border: none !important;
}
.drop-contract .drop-contract-link{
  color: #1890ff;
  background-color: transparent;
  border-color: transparent;
  -webkit-box-shadow: none;
  box-shadow: none;
  margin-top: 0.5em
}

.break_input{
  word-wrap: break-word;
}

.flex-row-only-direction{
  flex-direction: row!important;
}

.no-padding-left {
  padding-left: unset !important;
}
.no-padding-right {
  padding-right: unset !important;
}
.input-file-upload-non-height {
  height: unset !important;
  display: inherit !important;
}
