.red {
  color: red;
}

.login-form-forgot {
  float: right;
}

.limiter {
  background-repeat: no-repeat;
  background-position: center;
  background-size: cover;
  -ms-background-size: cover;
  -o-background-size: cover;
  -moz-background-size: cover;
  -webkit-background-size: cover;
  background-attachment: fixed;
}

.background-login-page::before {
  content: "";
  /* background-color: rgba(33,33,33,.6); */
  background-color: rgba(253, 253, 253, 0.5);
  display: block;
  position: absolute;
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
}
.customSelectLogin {
  border: none;
}
.login {
  width: 100vw;
  height: 100vh;
  box-sizing: border-box;
  background-color: white;
}
.login-wrapper {
  max-width: 1366px;
  max-height: 700px;
  width: 100%;
  height: 100%;
  top: 50%;
  right: 50%;
  -webkit-transform: translate(50%, -50%);
  transform: translate(50%, -50%);
  position: absolute;
  border: 1px solid #d1d1d1;
  border-radius: 30px;
  font-size: 16px;
  box-sizing: border-box;
}
.left-item img {
  border-top-left-radius: 30px;
  border-bottom-left-radius: 30px;
}
.ant-inputlogin {
  border-radius: 27px;
}
.forget-pass > a {
  font-size: 16px;
  font-weight: 600;
  opacity: 0.8;
}
.forget-pass > a:hover {
  opacity: 1;
}
.forget-pass:hover {
  text-decoration: underline;
}
.button-back {
  display: inline !important;
  background-color: white !important;
  color: black !important;
}
.button-back:hover {
  background-color: #d1d1d1 !important;
  border-color: #d1d1d1 !important;
}


@media screen and (max-width: 992px) {
  .left-item{
    display: none;
  }
  .right-item{
    width: 100% !important;
  }
}