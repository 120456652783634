.gen {
    .gen-backgroud {
        width: 70%;
        margin: auto;
    }
    .gen-backgroud .legend1 {
        margin: 0;
        width: 4em;
        padding-left: 5px;
        border-bottom: 0;
        font-size: 20px;
    }
    .gen-backgroud .legend2 {
        margin: 0;
        width: 4em;
        padding-left: 5px;
        border-bottom: 0;
        font-size: 20px;
        text-align: left;
    }
}
